
import {defineComponent, PropType} from "vue";
import Card from "@/components/shared/Card.vue";
import {ErrorFiles, OmrErrorResponseTextEnum} from "@/models";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: 'AnswerCardListItem',
  components: { Card },
  props: {
    filesWithError: {
      type: Array as PropType<ErrorFiles[]>
    }
  },
  methods: {
    closeErrorsCard (): void {
      this.$emit('closeErrorsCard');
    },

    getErrorText(error: OmrErrorResponseTextEnum): string {
      const { t } = useI18n();
      const errorMap: { [key: string]: string } = {
        [OmrErrorResponseTextEnum.API_GT_ERROR]: t('ANSWER_CARD.error_page_not_read'),
        [OmrErrorResponseTextEnum.FILE_IS_EMPTY]: t('ANSWER_CARD.error_page_not_read'),
        [OmrErrorResponseTextEnum.PAGE_NOT_READ]: t('ANSWER_CARD.error_page_not_read'),
        [OmrErrorResponseTextEnum.QR_NOT_DECODE]: t('ANSWER_CARD.error_qr_not_read'),
        [OmrErrorResponseTextEnum.STUDENT_ANSWER_NOT_DECODE]: t('ANSWER_CARD.error_student_not_decode'),
        [OmrErrorResponseTextEnum.TEACHER_ANSWER_NOT_DECODE]: t('ANSWER_CARD.error_teacher_not_decode'),
        [OmrErrorResponseTextEnum.TEST_NOT_FOUND]: t('ANSWER_CARD.error_test_not_found'),
        [OmrErrorResponseTextEnum.TEST_VERSION_NOT_FOUND]: t('ANSWER_CARD.error_test_not_found'),
        [OmrErrorResponseTextEnum.FILE_IS_TOO_BIG]: t('ANSWER_CARD.file_size_to_large'),
        [OmrErrorResponseTextEnum.INCORRECT_FORMAT]: t('ANSWER_CARD.format_incorrect'),
        [OmrErrorResponseTextEnum.INTERNAL_SERVER_ERROR]: t('ANSWER_CARD.error_page_not_read'),
        [OmrErrorResponseTextEnum.TAGS_NOT_MATCH]: t('ANSWER_CARD.error_tags_no_match'),
      }
      return errorMap[error] || t('ANSWER_CARD.error_answer_card_not_recognized');
    }
  }
})
