

import { defineComponent } from 'vue';
import { IonLoading } from '@ionic/vue';


export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  components: { IonLoading },
})
