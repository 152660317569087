

import { defineComponent } from 'vue';
import { popoverController } from '@ionic/vue';
import { numbersArrayFrom } from '@/utils/DataUtils';
import { notifySuccess } from '@/utils/NotificationUtils';

export default defineComponent({
  props: {
    answerIndex: {
      type: Number,
      default: 0
    },
    maxPoints: {
      type: Number,
      default: 0
    },
    currentPoints: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      numbers: [] as number[],
    }
  },
  components: { },
  mounted() {
    this.numbers = numbersArrayFrom(this.maxPoints + 1)
  },
  methods: {
    chooseNumber (points: number) {
      if (points !== this.currentPoints) {
        setTimeout(() => {
          notifySuccess('Liczba punktów uzyskana przez ucznia za to zadanie została zmieniona.');
        }, 1000);
        popoverController.dismiss({ answerIndex: this.answerIndex, points: points });
      }
    }
  }
})

