
import { defineComponent } from 'vue';
import Card from '@/components/shared/Card.vue';
import { IonProgressBar } from '@ionic/vue';


export default defineComponent ({
  name: 'AnswerCardLoader',
  components: { Card, IonProgressBar },
  props: {
    isLoaded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      progress: 0,
      interval: 0,
      displayProgress: 0,
    }
  },
  mounted() {
    this.initProgressBar();
  },
  unmounted() {
    clearInterval(this.interval)
  },
  methods: {
    initProgressBar() {
      this.progress = 0.1;
      this.interval = setInterval(() => {
        if (this.progress < 0.8) {
          this.progress = parseFloat((this.progress + 0.2).toFixed(1));
          this.displayProgress = this.displayProgress + 20;
        }
      }, Math.floor(Math.random() * (1200 - 1800)) + 1200);
    },
  }
})

