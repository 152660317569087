
import { defineComponent, PropType } from 'vue';
import { OmrAnswerCardGrouped } from '@/services/api/models/OmrAnswerCard';
import { GtTest } from '@/models/gt-test';
import Loader from '@/components/Loader.vue';
import { dataLayerPush } from '@/services/common/DataLayerService';
import { DataLayerEvent } from '@/services/common/DataLayerModel';
import { notifyWarn } from "@/utils/NotificationUtils";
import { generateReport } from "@/services/api/ApiRender";
import * as Sentry from '@sentry/browser';
import { downloadBlobFile } from '@/utils/DataUtils';
import { Config } from '@/utils/Config';


export default defineComponent({
  name: "DownloadResults",
  components: { Loader },
  props: {
    cardGroup: {
      type: Object as PropType<OmrAnswerCardGrouped>,
      default: null
    },
    answerKeys: {
      type: Object as PropType<GtTest[]>,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    userId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isDownloadButtonDisabled: false,
      isLoaderShown: false,
      isPdfRendered: false
    }
  },
  methods: {
    dataLayerPushRaportGenerate(fileName: string, testId: number): void {
      dataLayerPush(DataLayerEvent.RESULT_DOWNLOAD, {
        fileName: fileName,
        testId
      })
    },
    async generateReport() {
      try {
        if (this.cardGroup?.answerCardList.length > Config.cardLimit) {
          dataLayerPush(DataLayerEvent.RESULT_DOWNLOAD, { error: 'Jest więcej niż 48 kart w teście' });
          await notifyWarn(this.$t('ANSWER_CARD.answer_card_limit_warning'), 3000);
          return;
        }
        this.isDownloadButtonDisabled = true;
        this.isLoaderShown = true;
        const blob = await generateReport(this.cardGroup, this.answerKeys, this.userId);
        const fileName = `${this.cardGroup?.answerCardList[0].test.title.substring(0, 40)}_${new Date().toISOString().slice(0, 10)}.pdf`
        downloadBlobFile(fileName, blob);
        this.dataLayerPushRaportGenerate(fileName, this.cardGroup.id);
        this.isDownloadButtonDisabled = false;
        this.isLoaderShown = false;
      } catch (e: any) {
        dataLayerPush(DataLayerEvent.RESULT_DOWNLOAD, {isSuccess: false, error1: 'Something went wrong'});
        if (e) Sentry.captureMessage('generateReport failed: ' + e.toString());
        this.isDownloadButtonDisabled = false;
        this.isLoaderShown = false;
      }
    }
  }
})
