import { StudentAnswer } from '@/services/api/models/OmrAnswerCard';
import { AnswersType, ExerciseAnswerKey } from '@/models/gt-test';
import { getAnswerTypeEnumFromString } from '@/utils/DataUtils';

export const getExerciseScoreForSingleChoice = (userAnswer: StudentAnswer, exerciseAnswers: ExerciseAnswerKey, scoreMax: number): number => {
  const correctAnswerIndex = exerciseAnswers.answers!.findIndex(item => item.isCorrect);
  const isAnswerCorrect = userAnswer.answers.toString() === [[correctAnswerIndex]].toString();
  return isAnswerCorrect ? scoreMax : 0;
}

export const getExerciseScoreForMultipleChoice = (userAnswer: StudentAnswer, exerciseAnswers: ExerciseAnswerKey, scoreMax: number): number => {
  let correctAnswersCount = 0;
  exerciseAnswers.answers!.forEach((answer, index) => {
    if (answer.isCorrect && (userAnswer.answers[0] as number[]).includes(index)) correctAnswersCount++;
    if (!answer.isCorrect && !(userAnswer.answers[0] as number[]).includes(index)) correctAnswersCount++;
  })
  if ((userAnswer.answers[0] as number[]).length === 0) return 0;
  if (scoreMax === exerciseAnswers.answers!.length) return correctAnswersCount;
  return  Math.floor((scoreMax * correctAnswersCount) / exerciseAnswers.answers!.length);
}

export const getExerciseScoreForBoolean = (userAnswer: StudentAnswer, exerciseAnswers: ExerciseAnswerKey, scoreMax: number): number => {
  // true answer is under 0 index, and false answer is under 1
  const booleanSwitcher = [1, 0, 2];
  const correctAnswerIndexesArr = exerciseAnswers.answers!.map((item, index) => booleanSwitcher[item.booleanAnswer!]);
  let correctAnswersCount = 0;
  (userAnswer.answers as number[][]).forEach((item, index) => {
    const isAnswerCorrect = item.length === 1 && item[0] === correctAnswerIndexesArr[index];
    if (isAnswerCorrect) correctAnswersCount++;
  })
  if (scoreMax === exerciseAnswers.answers!.length) return correctAnswersCount;
  return  Math.floor((scoreMax * correctAnswersCount) / exerciseAnswers.answers!.length);
}

export const getExerciseScoreForBundle = (userAnswer: StudentAnswer, exerciseAnswers: ExerciseAnswerKey): number => {
  const correctAnswerIndexesArr = exerciseAnswers.bundles!.map(item => item.answers!.map((item, index) => {
    if (item.isCorrect) return index
  }).filter(item => item !== undefined));
  let correctAnswersCount = 0;
  (userAnswer.answers as number[][]).forEach((item, index) => {
    const isAnswerCorrect = item.length === 1 && item[0] === correctAnswerIndexesArr[index][0];
    if (isAnswerCorrect) correctAnswersCount++;
  })
  return  correctAnswersCount;
}

export const getExerciseScoreForJustification = (userAnswer: StudentAnswer, exerciseAnswers: ExerciseAnswerKey, scoreMax: number): number => {
  const correctAnswerIndexesArr = exerciseAnswers.answers!.map((item, index) => {if (item.isCorrect) return index}).filter(item => item !== undefined);
  const correctAdditionalAnswerIndexesArr = exerciseAnswers.additionalAnswers!.map((item, index) => {if (item.isCorrect) return index}).filter(item => item !== undefined);
  const isAnswerCorrect = JSON.stringify(userAnswer.answers) === JSON.stringify(correctAnswerIndexesArr);
  const isAdditionalAnswerCorrect = JSON.stringify(userAnswer.additionalAnswers) === JSON.stringify(correctAdditionalAnswerIndexesArr);
  return  isAnswerCorrect && isAdditionalAnswerCorrect ? scoreMax : 0;
}

export const getExerciseScoreForAssignment = (userAnswer: StudentAnswer, exerciseAnswers: ExerciseAnswerKey, scoreMax: number): number => {
  let correctAnswersCount = 0;
  userAnswer.answers!.forEach((item, index) => {
    if (JSON.stringify(item) === JSON.stringify(exerciseAnswers.additionalAnswers![index].correctAnswers)) correctAnswersCount++;
  });
  if (exerciseAnswers.additionalAnswers!.length === scoreMax) return correctAnswersCount;
  return  Math.floor((scoreMax * correctAnswersCount) / exerciseAnswers.additionalAnswers!.length);
}

export const getUserScore = (answersType: AnswersType, scoreMax: number, userAnswer: StudentAnswer, exerciseAnswer: ExerciseAnswerKey): number => {
  if (answersType === AnswersType.SINGLE_CHOICE) return getExerciseScoreForSingleChoice(userAnswer, exerciseAnswer, scoreMax);
  if (answersType === AnswersType.MULTIPLE_CHOICE) return getExerciseScoreForMultipleChoice(userAnswer, exerciseAnswer, scoreMax);
  if (answersType === AnswersType.BOOLEAN) return getExerciseScoreForBoolean(userAnswer, exerciseAnswer, scoreMax);
  if (answersType === AnswersType.BUNDLES) return getExerciseScoreForBundle(userAnswer, exerciseAnswer);
  if (answersType === AnswersType.JUSTIFICATION) return getExerciseScoreForJustification(userAnswer, exerciseAnswer, scoreMax);
  if (answersType === AnswersType.ASSIGNMENT) return getExerciseScoreForAssignment(userAnswer, exerciseAnswer, scoreMax);
  if (answersType === AnswersType.OPEN) return userAnswer.score;
  return scoreMax;
}

export const getUserScoreArr = (scoreQuestion: number[], userAnswers: StudentAnswer[], exerciseAnswers: ExerciseAnswerKey[]): number[] => {
  const arr = scoreQuestion.map((item, index) => {
    const answersTypeId: AnswersType = getAnswerTypeEnumFromString(userAnswers[index].type);
    return getUserScore(answersTypeId, scoreQuestion[index], userAnswers[index], exerciseAnswers[index])
  })
  return arr;
}



