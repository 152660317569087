import {OmrAnswerCard} from "@/services/api/models/OmrAnswerCard";
import {GtTest} from "@/models/gt-test";
import { toRaw } from "vue";
import {ErrorFiles, OmrErrorResponseTextEnum} from "@/models";

const DB_NAME = 'ondoriodb';
const DB_VERSION = 1;

export class IndexDB {
    DB: IDBDatabase = {} as IDBDatabase;
    userId: string

    constructor(props: {userId: string}) {
        this.userId = props.userId;
    }

    async init() {
        return new Promise((resolve, reject) => {
            console.log('OPENING DB', this.DB);
            const request = window.indexedDB.open(`${DB_NAME}_${this.userId}`, DB_VERSION);

            request.onerror = e => {
                console.log('Error opening db', e);
                reject(e);
            };

            request.onsuccess = (e: any) => {
                this.DB = e.target.result;
                resolve(this.DB);
            };

            request.onupgradeneeded = (e: any) => {
                const db = e.target.result;
                db.createObjectStore('answer-cards', { autoIncrement: true, keyPath:'id' });
                db.createObjectStore('answer-keys', { autoIncrement: true, keyPath:'id' });
                db.createObjectStore('answer-card-error', { autoIncrement: true, keyPath:'id' });
            };
        })
    }

    // Answer Card

    async getAnswerCards(): Promise<OmrAnswerCard[]> {
        return new Promise(resolve => {
            const answerCards: OmrAnswerCard[] = [];

            const trans = this.DB.transaction(['answer-cards'],'readonly');
            trans.oncomplete = () => {
                resolve(answerCards);
            };

            const store = trans.objectStore('answer-cards');

            store.openCursor().onsuccess = (e: any) => {
                const cursor = e.target.result;
                if (cursor) {
                    answerCards.push(cursor.value)
                    cursor.continue();
                }
            };
        });
    }

    async saveAnswerCards(answerCard: OmrAnswerCard) {
        return new Promise(resolve => {
            const trans = this.DB.transaction(['answer-cards'],'readwrite');
            trans.oncomplete = () => {
                resolve('saved');
            };

            const store = trans.objectStore('answer-cards');
            store.put(answerCard);
        });
    }

    async deleteAnswerCard(id: number) {
        return new Promise((resolve, reject) => {
            const trans = this.DB.transaction(['answer-cards'],'readwrite');
            trans.oncomplete = () => {
                resolve('deleted');
            };

            const store = trans.objectStore('answer-cards');
            store.delete(id);
        });
    }

    async updateAnswerCard(answerCard: OmrAnswerCard) {
        return new Promise(resolve => {
            const trans = this.DB.transaction(['answer-cards'],'readwrite');
            trans.oncomplete = () => {
                resolve('updated');
            };

            const store = trans.objectStore('answer-cards');
            const rawObject = toRaw(answerCard)
            store.put(rawObject);
        });
    }

    // Answer Key

    async getAnswerKeys(): Promise<GtTest[]> {
        return new Promise(resolve => {
            const answerKeys: GtTest[] = [];

            const trans = this.DB.transaction(['answer-keys'],'readonly');
            trans.oncomplete = () => {
                resolve(answerKeys);
            };

            const store = trans.objectStore('answer-keys');

            store.openCursor().onsuccess = (e: any) => {
                const cursor = e.target.result;
                if (cursor) {
                    answerKeys.push(cursor.value)
                    cursor.continue();
                }
            };
        });
    }

    async saveAnswerKey(answerKey: GtTest) {
        return new Promise(resolve => {
            const trans = this.DB.transaction(['answer-keys'],'readwrite');
            trans.oncomplete = () => {
                resolve('saved');
            };

            const store = trans.objectStore('answer-keys');
            store.put(answerKey);
        });
    }

    // Answer Card Error

    async getAnswerCardError(): Promise<{fileName: string; error: OmrErrorResponseTextEnum}[]> {
        return new Promise(resolve => {
            const answerCardErrors: {fileName: string; error: OmrErrorResponseTextEnum}[] = [];

            const trans = this.DB.transaction(['answer-card-error'],'readonly');
            trans.oncomplete = () => {
                resolve(answerCardErrors);
            };

            const store = trans.objectStore('answer-card-error');

            store.openCursor().onsuccess = (e: any) => {
                const cursor = e.target.result;
                if (cursor) {
                    answerCardErrors.push(cursor.value)
                    cursor.continue();
                }
            };
        });
    }

    async saveAnswerCardError(answerCardError: {fileName: string; error: OmrErrorResponseTextEnum}) {
        return new Promise(resolve => {
            const trans = this.DB.transaction(['answer-card-error'],'readwrite');
            trans.oncomplete = () => {
                resolve('saved');
            };

            const store = trans.objectStore('answer-card-error');
            store.put(answerCardError);
        });
    }

    async deleteAllAnswerCardErrors() {
        return new Promise(resolve => {
            const trans = this.DB.transaction(['answer-card-error'],'readwrite');
            trans.oncomplete = () => {
                resolve('deleted');
            };

            const store = trans.objectStore('answer-card-error');
            store.clear();
        });
    }
}
