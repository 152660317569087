
import { computed, defineComponent, onMounted, PropType, reactive, toRef, toRefs, watch } from 'vue';
import { AnswersType, ExerciseAnswerKey, ExerciseVariant, GtTest } from '@/models/gt-test';
import ModalNav from '@/components/ModalNav.vue';
import ModalNavItem from '@/components/ModalNavItem.vue';
import { alphabeticCharFromCode } from '@/utils/StringUtils';
import { isExerciseOld } from '@/utils/DataUtils';
import { dataLayerPush } from '@/services/common/DataLayerService';
import { DataLayerEvent } from '@/services/common/DataLayerModel';

export default defineComponent ({
  name: 'AnswerKeyModal',
  components: { ModalNavItem, ModalNav },
  emits: ['update:exerciseNumberToScroll'],
  setup (props, { emit }) {

    const state = reactive({
      currentVariantIndex: 0
    })

    const currentVariant = computed(() => props.gtTest.variants[state.currentVariantIndex])
    const title = computed(() => props.testTitle.length > 128 ? props.testTitle.slice(0, 128) : props.testTitle)

    onMounted(() => {
      scrollToCurrentElement();
    })

    const getComment = (exercise: ExerciseAnswerKey): string | null => {
      return isExerciseOld(exercise.answersType) ? exercise.comment : null
    }

    const scrollToCurrentElement = () => {
      if (props.exerciseNumberToScroll) {
        state.currentVariantIndex = props.variantIndexToScroll;

        // timeout due to bug in ionic, which is fixed in version 6. Ionic cut vue reactivity in ion-modal
        setTimeout(() => {
          const elementIdToScroll = 'ex' + props.exerciseNumberToScroll;
          const element = document.getElementById(elementIdToScroll);
          (element as HTMLElement).scrollIntoView({ block: 'center', behavior: 'smooth' });
          emit('update:exerciseNumberToScroll', null)
        }, 500);
      }
    }

    const getIsExerciseOld = (answersType: AnswersType): boolean => {
      return isExerciseOld(answersType);
    }

    const getTestGroup = (variantIndex: number | string): string => {
      return alphabeticCharFromCode(variantIndex);
    }

    const onCurrentIndexChange = (index: number): void => {
      if (index !== state.currentVariantIndex) {
        state.currentVariantIndex = index;
        dataLayerPush(DataLayerEvent.ANSWER_KEY_GROUP_CHANGE, { tabName: alphabeticCharFromCode(index) })
      }
    }

    return {
      ...toRefs(state),
      currentVariant,
      title,
      getTestGroup,
      onCurrentIndexChange,
      getIsExerciseOld,
      getComment
    }
  },
  props: {
    exerciseNumberToScroll: {
      type: Number,
      default: 0
    },
    scoreQuestion: {
      type: Array as PropType<number[]>,
      default: null
    },
    variantIndexToScroll: {
      type: Number,
      default: 0
    },
    variantIndex: Number,
    gtTest: {
      type: Object as PropType<GtTest>,
      default: null
    },
    testTitle: {
      type: String,
      default: ''
    }
  },
});

