import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { onResponseFailure, onResponseSuccess } from "@/services/api/Utils";
import { OmrAnswerCardGrouped } from "@/services/api/models/OmrAnswerCard";
import { GtTest } from "@/models/gt-test";
import { env } from "@/store";

const RENDER_ONDORIO_REPORT = '/ondorio/report'

const instance: Readonly<AxiosInstance> = axios.create({
    baseURL: env.value.renderUrl,
    timeout: 120000
});

instance.interceptors.response.use(onResponseSuccess, onResponseFailure);
instance.interceptors.request.use((config) => {
    return config;
});

export const generateReport = async (cardGroup: OmrAnswerCardGrouped, answerKeys: GtTest[], userId: number) => {
    const res = await instance.post(`${RENDER_ONDORIO_REPORT}`, {
        cardGroup,
        answerKeys,
        userId
    }, {responseType: 'blob'});
    return res?.data;
}

export default instance;
