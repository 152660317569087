
import { Camera, CameraDirection, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { defineComponent } from 'vue';
import AnswerCardAdd from '@/components/AnswerCardAdd.vue';
import { blobFromBase64 } from '@/utils/BlobUtils';
import { notifyError } from '@/utils/NotificationUtils';
import { isIOS } from '@/utils/DeviceUtils';
import { isFirstAppEnter } from '@/store';
import { dataLayerPush } from '@/services/common/DataLayerService';
import { DataLayerEvent } from '@/services/common/DataLayerModel';
import * as Sentry from '@sentry/browser';


export default defineComponent({
  name: 'Camera',
  components: { AnswerCardAdd },
  props: {
    buttonText: {
      type: String,
      default: 'Zrób zdjęcie',
      validator: (propValue: string) => {
        return propValue.length > 0;
      }
    },
    cameraSource: {
      type: String,
      default: CameraSource.Camera
    },
    isBubbleSpeechOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    isIos(): boolean {
      return isIOS();
    },
    showBubble(): boolean {
      return isFirstAppEnter.value && this.isBubbleSpeechOpen
    },
  },
  methods: {
    closeBubbleSpeech () {
      this.$emit('onBubbleSpeechClose');
    },
    async hasAccessToCamera(): Promise<boolean> {
      let hasCameraAccess = true;
      if (navigator.mediaDevices) {
        try {
          const videoStream = await navigator.mediaDevices.getUserMedia({
            video: true
          });
          dataLayerPush(DataLayerEvent.CAMERA_CONSENT, { isSuccess: true });
        } catch (error) {
          notifyError('Nie mogliśmy sprawdzić uprawnień do Twojej kamery.');
          dataLayerPush(DataLayerEvent.CAMERA_CONSENT, { isSuccess: false });
          hasCameraAccess = false
        }
      } else {
        notifyError('Nie mogliśmy sprawdzić uprawnień do Twojej kamery.');
        dataLayerPush(DataLayerEvent.CAMERA_CONSENT, { isSuccess: false });
        return false
      }
      return hasCameraAccess
    },
    async takePicture (): Promise<void> {
      let hasCameraAccess = true;
      // We don't check access to camera for ios
      if (!this.isIos) hasCameraAccess = await this.hasAccessToCamera();
      if (this.isIos || hasCameraAccess) {
        try {
          const photo: Photo = await Camera.getPhoto({
            allowEditing: false,
            direction: CameraDirection.Rear,
            quality: 100,
            resultType: CameraResultType.Base64,
            saveToGallery: true,
            source: this.cameraSource as CameraSource,
            // allows use PWA elements
            webUseInput: true
          });
          const blob = blobFromBase64(photo.base64String as string);
          this.$emit('onTakePicture', {blob: blob as Blob, filesLength: 1, isMultipleUpload: false});
        } catch (e: any) {
          Sentry.captureMessage(e.toString())
          // TODO: add notification component
        }
      }
    }
  }
})
