
import { defineComponent, onMounted, PropType } from 'vue';
import { OmrAnswerCard } from '@/services/api/models/OmrAnswerCard';
import { AnswersType, ExerciseVariant, GtTest } from '@/models/gt-test';
import { alphabeticCharFromCode } from '@/utils/StringUtils';
import { allAnswersMarkedExerciseArr, getCurrentVariant, isExerciseOld } from '@/utils/DataUtils';
import AnswerCardListHeader from './shared/AnswerCardListHeader.vue';
import AnswerCardListItem from '../views/AnswerCard/AnswerCardList/AnswerCardItem/AnswerCardListItem.vue';
import { GoToAnswerKeyEventData } from '@/views/AnswerCard/models';
import { popoverController } from '@ionic/vue';
import NumberTiles from '@/components/NumberTiles.vue';


export default defineComponent({
  name: "AnswerCardPunctation",
  components: { AnswerCardListHeader, AnswerCardListItem},
  props: {
    studentCard: {
      type: Object as PropType<OmrAnswerCard>,
      default: null
    },
    answerKey: {
      type: Object as PropType<GtTest>,
      default: null
    }
  },
  methods: {
    shouldAnimate (answer: any, index: number) {
      return this.animatedAnswers.includes(index) && (answer.isChanged ?? false);
    },
    async openMenu(answerIndex: number, maxPoints: number, currentPoints: number): Promise<void> {
      const popover = await popoverController
        .create({
          component: NumberTiles,
          componentProps: {
            answerIndex: answerIndex,
            maxPoints: maxPoints,
            currentPoints: currentPoints
          },
          cssClass: 'number-tiles',
          translucent: true
        })
      await popover.present();
      const { data } = await popover.onDidDismiss();
      this.changeStudentPoints({...data, itemId: this.studentCard!.id });
      this.animatedAnswers.push(answerIndex);
      setTimeout(() => {
        this.animatedAnswers.slice(answerIndex);
      }, 4000);

    },
    getStudentAnswer (answersType: AnswersType, index: number): any {
      return answersType === AnswersType.JUSTIFICATION ? this.studentCard.score.answer[index].answers : this.studentCard.score.answer[index].answers[0]
    },
    isExerciseOld(answersType: AnswersType): boolean {
      return isExerciseOld(answersType)
    },
    changeStudentNumber(event: Event) {
      this.$emit('changeStudentNumber', event );
    },
    changeStudentPoints(data: object) {
      this.$emit('changeStudentPoints', data)
    },
    goToSelectedAnswerKey (index: number): void {
      const currentVariant: ExerciseVariant = getCurrentVariant(this.answerKey.variants, this.studentCard.test.variantId);
      const variantIndex = this.answerKey.variants.indexOf(currentVariant);

      const event: GoToAnswerKeyEventData = { exerciseIndex: index, variantIndex }
      if (isExerciseOld(currentVariant.exercise[index].answersType)) this.$emit('goToAnswerKey', event );
    }
  },
  setup: (props) => {
    const currentVariant: ExerciseVariant = getCurrentVariant(props.answerKey.variants, props.studentCard.test.variantId);
    const group = alphabeticCharFromCode(props.answerKey.variants.indexOf(currentVariant));
    const isNumberTilePopoverOpen = false;
    const isChangePointsTooltipVisible = false;
    const animatedAnswers: number[] = [];
    const getAllAnswersMarkedExerciseArr = allAnswersMarkedExerciseArr(props.studentCard, props.answerKey);

    onMounted(async () => {
      const style = document.createElement('style');
      style.innerHTML = `.exercise_wrapper { display: flex; justify-content: center; width: 100%; } .exercise { width: initial !important; } .exercise__content { width: initial !important } .exercise__content-container { width: initial !important }`;
      const answerKeyEl = document.getElementsByClassName('answer-key');
      for (const el of answerKeyEl) {
        (el.shadowRoot as any).innerHTML += style.outerHTML;
      }
    })
    return {
      currentVariant,
      group,
      isNumberTilePopoverOpen,
      isChangePointsTooltipVisible,
      getAllAnswersMarkedExerciseArr,
      animatedAnswers
    }
  },
})
