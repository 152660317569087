
import { defineComponent } from 'vue';
import {isMobile} from "@/utils/DeviceUtils";

export default defineComponent({
  name: 'FileUpload',
  props: {
    buttonText: {
      type: String,
      default: 'Wybierz plik',
      validator: (propValue: string) => {
        return propValue.length > 0;
      }
    },
  },
  methods: {
    uploadFile (): void {
      const input: HTMLInputElement | null = document.querySelector('input[type=file]');
      if (input?.files?.length) {
        const filesArray = Array.from(input.files);
        const isMultipleFiles = filesArray.length > 1;
        if (isMultipleFiles) {
          this.$emit('onTakeMultiplePicture', filesArray);
        } else {
          for (const file of filesArray) {
            const blob: Blob | null = file;
            this.$emit('onTakePicture', {blob: blob as Blob, filesLength: filesArray.length, isMultipleUpload: false});
          }
          // allows upload the same file multiple time
          input.value = ''
        }
        input.value = ''
      }
    }
  },
  computed: {
    acceptableFormats(): string {
      return isMobile() ? 'image/jpeg, image/heic' : '.jpg, .jpeg, .heic, .heiv';
    }
  }
})
