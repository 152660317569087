import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      disabled: _ctx.isDownloadButtonDisabled || _ctx.disabled,
      class: _normalizeClass([_ctx.isDownloadButtonDisabled || _ctx.disabled ? 'gt-scores-download-results__button--disabled' : null, "gt-scores-download-results__button"]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.generateReport && _ctx.generateReport(...args)))
    }, _toDisplayString(_ctx.$t('OTHERS.download_results')), 11, _hoisted_1),
    (_ctx.isLoaderShown)
      ? (_openBlock(), _createBlock(_component_Loader, {
          key: 0,
          "is-loading": _ctx.isLoaderShown
        }, null, 8, ["is-loading"]))
      : _createCommentVNode("", true)
  ], 64))
}