
import {IonContent, IonPage} from '@ionic/vue';
import {computed, defineComponent, onMounted, reactive, Ref, ref, toRefs, watch} from 'vue';
import {formatBytesToKilobytes} from '@/utils/BlobUtils';
import {OmrAnswerCardUpload} from '@/services/api/ApiOmr';
import { OmrAnswerCard, OmrAnswerCardGrouped, StudentAnswer } from '@/services/api/models/OmrAnswerCard';
import Camera from '@/components/Camera.vue';
import AnswerCardList from '@/views/AnswerCard/AnswerCardList/AnswerCardList.vue';
import {trashOutline} from 'ionicons/icons';
import Header from '@/components/shared/Header.vue';
import Card from '@/components/shared/Card.vue';
import Modal from '@/components/shared/Modal.vue';
import AnswerCardModal from '@/views/AnswerCard/AnswerCardModal/AnswerCardModal.vue';
import AnswerCardListHeader from '@/components/shared/AnswerCardListHeader.vue';
import {env, isLogged, saveIsFirstAppEnterToStore, userId, userToken} from '@/store';
import AnswerKeyModal from '@/views/AnswerKeyModal.vue';
import {getTest} from '@/services/api/ApiGT';
import { ExerciseVariant, GtTest } from '@/models/gt-test';
import Loader from '@/components/Loader.vue';
import {notifyError, notifySuccess} from '@/utils/NotificationUtils';
import FileUpload from '@/components/FileUpload.vue';
import {EnvType} from '@/services/api/Env';
import {useI18n} from 'vue-i18n';
import AnswerCardAddInfo from '@/views/AnswerCard/AnswerCardAddInfo/AnswerCardAddInfo.vue';
import { isIOS, isMobile } from '@/utils/DeviceUtils';
import AnswerCardLoader from '@/views/AnswerCard/AnswerCardLoader/AnswerCardLoader.vue';
import {ErrorFiles, FileUploadModel, OmrErrorResponseTextEnum} from '@/models';
import DownloadResults from '@/components/DownloadResults.vue';
import {categoryInfo, CategoryInfoLevel, generateHash, groupFilesByError} from '@/utils/DataUtils';
import {dataLayerPush} from '@/services/common/DataLayerService';
import {DataLayerAdditionalInfo, DataLayerEvent} from '@/services/common/DataLayerModel';
import {useRouter} from "vue-router";
import {IndexDB} from "@/services/api/IndexDB";
import AnswerCardErrors from "@/views/AnswerCard/AnswerCardErrors/AnswerCardErrors.vue";
import {ROUTE_HASH} from "@/router/routeNames";
import { GoToAnswerKeyEventData } from '@/views/AnswerCard/models';
import { answerCardMock } from '@/utils/mocks';
import { getUserScoreArr } from '@/services/common/UserScoreService';
import { Config } from '@/utils/Config';

interface LoaderItem {
  id: Date;
  loaded: boolean;
}

export default defineComponent({
  components: {
    AnswerCardLoader,
    AnswerCardAddInfo,
    FileUpload,
    Loader,
    AnswerKeyModal,
    AnswerCardListHeader,
    AnswerCardModal,
    AnswerCardList,
    AnswerCardErrors,
    Camera,
    DownloadResults,
    IonContent,
    IonPage,
    Header,
    Card,
    Modal
  },
  setup() {
    const router = useRouter();
    const content = ref(null) as Ref;
    const scrollToTop = () => {
      content.value.$el.scrollToTop(300);
    }
    const isLoggedIn = computed(() => isLogged.value);
    const { t } = useI18n();
    const indexDB = {} as IndexDB;
    onMounted (() => {
      saveIsFirstAppEnterToStore();
      handleIndexDbLoad();
    })

    const isRemoveBtnVisible = computed(() => {
      // temporary disabled
      // return !state.cardListFlat.some(item => item.isChecked);
      return false
    })

    const state = reactive({
      currentScoreQuestion: [] as number[],
      answerKeys: [] as GtTest[],
      cardListFlat: [] as OmrAnswerCard[],
      cardListGrouped: [] as OmrAnswerCardGrouped[],
      cardListIdKeys: [] as string[],
      checkedAnswerCards: 0,
      config: Config,
      currentAnswerKey: null as null | GtTest,
      currentStudentCard: null as null | OmrAnswerCard,
      filesWithError: [] as ErrorFiles[],
      isAnswerCardModalOpen: false,
      isAnswerKeyLoading: false,
      isAnswerKeyModalOpen: false,
      isConfirmationModalOpen: false,
      isMobile: isMobile(),
      isBubbleSpeechOpen: true,
      isMultipleFiles: false,
      exerciseIndexToScroll: null as null | number,
      exerciseVariantToScroll: null as null | number,
      lastCheckedCard: {} as OmrAnswerCard,
      loaderItemList: [] as LoaderItem[],
      omrResponse: null as string | null,
      processedFilesLength: 0,
      errorFilesLength: 0,
      userId: userId.value,
      t,
      indexDB
    })

    const goToAnswerKey = (event: GoToAnswerKeyEventData) => {
      state.isAnswerCardModalOpen = false;
      router.push({name: 'answer-card', hash: ''});
      state.exerciseIndexToScroll = event.exerciseIndex;
      state.exerciseVariantToScroll = event.variantIndex;
      setTimeout(() => {
        onAnswerKeyModalOpen((state.currentStudentCard as OmrAnswerCard).test.hash, state.currentStudentCard!.score.scoreQuestion)
      }, 500);
    }

    const handleIndexDbLoad = async () => {
      state.indexDB = new IndexDB({userId: userId.value as string});
      state.cardListFlat = [];
      state.cardListGrouped = [];
      state.filesWithError = [];
      await state.indexDB.init();

      state.answerKeys = await state.indexDB.getAnswerKeys();
      const answerCards = await state.indexDB.getAnswerCards();
      const dbErrors = await state.indexDB.getAnswerCardError();

      answerCards.forEach((omrAnswerCard: OmrAnswerCard) => {
        handleAnswerCard(omrAnswerCard, true);
      });

      dbErrors.forEach(err => {
        state.filesWithError = groupFilesByError(state.filesWithError, err.fileName, err.error);
      });
    }

    const openConfirmationModal = (shouldOpen = true) => {
      shouldOpen ?
          router.push({name: 'answer-card', hash: ROUTE_HASH.DELETE_CARD_MODAL}) :
          router.replace({name: 'answer-card', hash: ''});
      state.isConfirmationModalOpen = shouldOpen;
    }

    const closeConfirmationModal = () => {
      state.isConfirmationModalOpen = false;
    }

    const closeErrorsCard = async () => {
      state.filesWithError.length = 0;
      await state.indexDB.deleteAllAnswerCardErrors();
    }

    const setAnswerCardModalOpen = async (shouldOpen = true): Promise<void> => {
      state.isAnswerCardModalOpen = shouldOpen;
      if (!shouldOpen && !isIOS()) await router.replace({name: 'answer-card', hash: ''});
    }

    const clearLastCheckedCard = (): void => {
      state.lastCheckedCard = {} as OmrAnswerCard;
    }

    const getTestAnswerKey = async (testId: number, hash: string) => {
      try {
        const currentAnswerKey = await getTest(testId, userToken.value || '', hash);
        state.answerKeys.push(currentAnswerKey);
        await state.indexDB.saveAnswerKey(currentAnswerKey);
        // TODO trzeba poczekać aż Krystian ujednolici model zwracanych błędów
      } catch (e: any) {
        await notifyError(e.message);
      }
    }

    const getCurrentAnswerKey = (hash: string): GtTest => {
      return state.answerKeys.find(item => item.hash === hash) as GtTest;
    }

    const getCurrentAnswerKeys = (testId: number): GtTest[] => {
      return state.answerKeys.filter(item => item.testId === testId) as GtTest[];
    }

    const getCurrentAnswerCard = (id: number): OmrAnswerCard => {
      return state.cardListFlat.find(el => el.id === id) as OmrAnswerCard;
    }

    const onAnswerCardModalOpen = (id: number): void => {
      const currentStudentCard = getCurrentAnswerCard(id)
      state.currentStudentCard = currentStudentCard;
      state.isAnswerCardModalOpen = true;
      router.push({name: 'answer-card', hash: ROUTE_HASH.ANSWER_CARD_MODAL});
      dataLayerPush(DataLayerEvent.ANSWER_CARD_MODAL_OPEN, { answerCardId: currentStudentCard.hash });
    }

    const setAnswerKeyModalOpen = (shouldOpen = true): void => {
      state.isAnswerKeyModalOpen = shouldOpen;
    }

    const onAnswerKeyModalOpen = async (hash: string, scoreQuestion: number[]): Promise<void> => {
      const currentAnswerKey = getCurrentAnswerKey(hash)
      state.currentAnswerKey = currentAnswerKey;
      state.currentScoreQuestion = scoreQuestion;
      await router.push({name: 'answer-card', hash: ROUTE_HASH.ANSWER_KEY_MODAL});
      state.isAnswerKeyModalOpen = true;
      dataLayerPush(DataLayerEvent.ANSWER_KEY_OPEN, { testId: currentAnswerKey.testId })
    }

    const groupByTestHash = (omrAnswerCard: OmrAnswerCard, cardListGrouped: OmrAnswerCardGrouped[]): OmrAnswerCardGrouped[] => {
      const existingGroup: OmrAnswerCardGrouped | undefined = cardListGrouped.find(item => item.hash === omrAnswerCard.test.hash);
      if (existingGroup) {
        existingGroup.hash = omrAnswerCard.test.hash === existingGroup.hash ? existingGroup.hash : omrAnswerCard.test.hash;
        existingGroup.answerCardList.push(omrAnswerCard);
        existingGroup.answerCardList.sort((a: OmrAnswerCard, b: OmrAnswerCard) => {
          // moves answer cards with studentNumber = 0 to the end of array;
          if (a.studentNumber === 0) return 0;
          if (b.studentNumber === 0) return -1;
          return a.studentNumber - b.studentNumber;
        })
      } else {
        cardListGrouped.push({ id: omrAnswerCard.test.testId as number, answerCardList: [omrAnswerCard], isAnswerKeyFetched: false, hash: omrAnswerCard.test.hash});
      }
      return cardListGrouped;
    }

    const getTestTitle = (hash: string) => {
      return (state.cardListGrouped.find(item => item.hash === hash) as OmrAnswerCardGrouped).answerCardList[0].test.title;
    }

    const isCameraFormatCorrect = (format: string): boolean => {
      const availableFormats = ['image/jpg', 'image/heic', 'image/heiv', 'image/jpeg'];
      return format !== null && format !== undefined && availableFormats.includes(format);
    }

    const isFileSizeCorrect = (fileSize: number): boolean => {
      const MAX_FILE_SIZE_IN_KB = 10000;
      return formatBytesToKilobytes(fileSize) < MAX_FILE_SIZE_IN_KB
    }

    const initProgressBar = (date: Date): void => {
      const loaderItem: LoaderItem = { id: date, loaded: false };
      state.loaderItemList.push(loaderItem);
      scrollToTop();
    }

    const clearProgressBar = (date: Date): void => {
      const item = state.loaderItemList.find(item => item.id === date);
      if (item) item.loaded = true;
      state.loaderItemList = state.loaderItemList.filter(item => item.id !== date);
    }


    const pushAnswerCardToDataLayer = (omrAnswerCard: OmrAnswerCard, isFileUpload: boolean) => {
      const answerCardInfo: DataLayerAdditionalInfo = {
        answerCardId: omrAnswerCard.hash,
        educationLevel: categoryInfo(omrAnswerCard.test.categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
        isFileUpload,
        isSuccess: true,
        subject: categoryInfo(omrAnswerCard.test.categoryKey, CategoryInfoLevel.SUBJECT),
        testId: omrAnswerCard.test.testId
      }
      dataLayerPush(DataLayerEvent.ADD_ANSWER_CARD, answerCardInfo);
    }

    const handleAnswerCard = (omrAnswerCard: OmrAnswerCard, fromIndexedDB = false) => {
      state.cardListFlat.push(omrAnswerCard);
      state.cardListGrouped = groupByTestHash(omrAnswerCard, state.cardListGrouped);
      state.checkedAnswerCards = state.cardListFlat.length;
      state.omrResponse = JSON.stringify(omrAnswerCard);
      state.lastCheckedCard = !fromIndexedDB ? state.cardListFlat[state.cardListFlat.length - 1] : {} as OmrAnswerCard;

      if (fromIndexedDB || state.answerKeys.length) {
        state.cardListGrouped.forEach(item => {
          item.isAnswerKeyFetched = true;
        });
      }
    }

    const updateTotalScoreUser = (scoreUserAnswer: number[]) => {
      return scoreUserAnswer.reduce((a, b) => a + b, 0)
    }

    const updateAnswerCardScore = (omrAnswerCard: OmrAnswerCard): void => {
      const currentAnswerKey: GtTest = (state.answerKeys.find(item => item.hash === omrAnswerCard.test.hash) as GtTest);
      const answerKeyVariant: ExerciseVariant = (currentAnswerKey.variants.find(item => item.variantId === omrAnswerCard.test.variantId) as ExerciseVariant);
      omrAnswerCard.score.scoreUserAnswer = getUserScoreArr(omrAnswerCard.score.scoreQuestion, omrAnswerCard.score.answer, answerKeyVariant!.exercise);
      omrAnswerCard.score.scoreUser = updateTotalScoreUser(omrAnswerCard.score.scoreUserAnswer);
    }

    const handleSuccessProcessResponse = async (omrAnswerCard: OmrAnswerCard, isFileUpload: boolean): Promise<void> => {
      omrAnswerCard.id = Date.now();
      // methods must be called with the certain order. In other case, app will throw errors
      if (!state.answerKeys.map(item => item.hash).includes(omrAnswerCard.test.hash)) {
        await getTestAnswerKey(omrAnswerCard.test.testId, omrAnswerCard.test.hash);
      }
      updateAnswerCardScore(omrAnswerCard);
      await handleAnswerCard(omrAnswerCard);
      (state.cardListGrouped.find(item => item.hash === omrAnswerCard.test.hash) as OmrAnswerCardGrouped).isAnswerKeyFetched = true;
      await state.indexDB.saveAnswerCards(omrAnswerCard);
      pushAnswerCardToDataLayer(omrAnswerCard, isFileUpload);
    }

    const saveErrorInDB = (fileName: string, error: OmrErrorResponseTextEnum): void => {
      state.indexDB.saveAnswerCardError({fileName, error});
    }

    const processCard = async (omrAnswerCard: OmrAnswerCard, data: FileUploadModel, isFileUpload: boolean): Promise<void> => {
      state.processedFilesLength++;
      await handleSuccessProcessResponse(omrAnswerCard, isFileUpload);
    }

    const uploadPicture = async (data: FileUploadModel, isFileUpload: boolean): Promise<void> => {
      if (!data.isMultipleFiles) {
        state.isMultipleFiles = false;
      }
      const hashedId = generateHash(Number(new Date()).toString())
      const formData = new FormData();
      formData.append('file', data.blob);
      formData.append('hash', hashedId);
      formData.append('device', navigator.userAgent);
      formData.append('userId', userId.value as string);
      const itemIndex: Date = new Date();
      try {
        if (!isFileUpload) {
          state.isBubbleSpeechOpen = false;
        }
        if (isCameraFormatCorrect(data.blob.type) && isFileSizeCorrect(data.blob.size)) {
          initProgressBar(itemIndex);
          const omrAnswerCard: OmrAnswerCard = await OmrAnswerCardUpload(formData);
          await processCard(omrAnswerCard, data, isFileUpload);
        } else {
          !isFileSizeCorrect(data.blob.size) ?
            (
                state.filesWithError = groupFilesByError(state.filesWithError, data.blob.name, OmrErrorResponseTextEnum.FILE_IS_TOO_BIG),
                saveErrorInDB(data.blob.name, OmrErrorResponseTextEnum.FILE_IS_TOO_BIG),
                dataLayerPush(DataLayerEvent.ADD_ANSWER_CARD, { isSuccess: false, error1: OmrErrorResponseTextEnum.FILE_IS_TOO_BIG, isFileUpload })
            ) :
            (
                state.filesWithError = groupFilesByError(state.filesWithError, data.blob.name, OmrErrorResponseTextEnum.INCORRECT_FORMAT),
                saveErrorInDB(data.blob.name, OmrErrorResponseTextEnum.INCORRECT_FORMAT),
                dataLayerPush(DataLayerEvent.ADD_ANSWER_CARD, { isSuccess: false, error1: OmrErrorResponseTextEnum.INCORRECT_FORMAT, isFileUpload })
            );
          state.errorFilesLength++;
        }
      } catch (e: any) {
        state.errorFilesLength++;
        state.filesWithError = groupFilesByError(state.filesWithError, data.blob.name, e.error);
        saveErrorInDB(data.blob.name, e.error);
        console.warn(JSON.stringify(e));

        state.omrResponse = JSON.stringify(e.error);
        dataLayerPush(DataLayerEvent.ADD_ANSWER_CARD, { isSuccess: false, error1: e.error, isFileUpload })
      } finally {
        clearProgressBar(itemIndex);
        if (data.isMultipleFiles && state.processedFilesLength === (data.filesLength - state.errorFilesLength) && state.processedFilesLength !== 0) {
          await notifySuccess( `Karty odczytane poprawnie (${state.processedFilesLength})`);
        }
      }
    }

    const uploadMultiplePictures = async (files: any[]): Promise<void> => {
      state.processedFilesLength = 0;
      state.errorFilesLength = 0;
      state.isMultipleFiles = true;
      dataLayerPush(DataLayerEvent.MULTIPLE_UPLOAD, {filesLength: files.length, isFileUpload: true});
      files.forEach((file: File) => {
        uploadPicture({blob: file, filesLength: files.length, isMultipleFiles: true}, true);
      });
    }

    const removeAnswerCards = () => {
      state.cardListFlat.forEach(async card => {
        await state.indexDB.deleteAnswerCard(card.id as number);
      });
      state.cardListFlat = [];
      state.cardListGrouped = [];
      closeConfirmationModal();
    }

    watch(isLoggedIn, async (newValue, oldValue) => {
      if (newValue) await handleIndexDbLoad();
      const isUserLoggingOut = oldValue && !newValue;
      if (isUserLoggingOut) {
        await state.indexDB.deleteAllAnswerCardErrors();
        state.filesWithError = [];
      }
    });

    const addMockAnswerCard = (): void => {
      if (env.value.env !== EnvType.PROD) handleSuccessProcessResponse(answerCardMock, true);
    }

    const isDeleteButtonVisible = (id?: number): boolean => {
      if (id) {
        const answerCard = state.cardListGrouped.find(item => item.id === id);
        return answerCard?.answerCardList.some((item: OmrAnswerCard) => item.isChecked) || false;
      }
      return state.cardListFlat.some(item => item.isChecked);
    }

    const allCardsInGroupChecked = (hash: string): boolean => {
      const answerCard = state.cardListGrouped.find(item => item.hash === hash);
      return answerCard?.answerCardList
          .filter(answerCard => answerCard.isChecked).length === answerCard?.answerCardList.length;
    }

    const dataLayerPushDeleteCards = (hash: string, testId: number, categoryKey: string): void => {
      dataLayerPush(DataLayerEvent.DELETE_ANSWER_CARD, {
        answerCardId: hash,
        testId: testId,
        educationLevel: categoryInfo(categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
        subject: categoryInfo(categoryKey, CategoryInfoLevel.SUBJECT),
      });
    }

    const deleteCheckedCards = (): void => {
      const answerCardIds = state.cardListGrouped.map(item => item.hash);
      const cardsToDelete: any[] = [];
      answerCardIds.forEach(hash => {
        const answerCard = state.cardListGrouped.find(item => item.hash === hash);
        if (answerCard) {
          answerCard.answerCardList = answerCard.answerCardList.filter(answerCard => {
            const isAnswerCardToDelete = answerCard.isChecked;
            if (isAnswerCardToDelete) {
              cardsToDelete.push(answerCard);
              dataLayerPushDeleteCards(answerCard.hash, answerCard.test.testId, answerCard.test.categoryKey)
              return false;
            }
            return true;
          });

          if (!answerCard.answerCardList.length) {
            state.cardListGrouped = state.cardListGrouped.filter(item => item.hash !== hash);
            state.answerKeys = state.answerKeys.filter(item => item.hash !== answerCard.hash);
          }
        }
      });
      cardsToDelete.forEach(async card => {
        await state.indexDB.deleteAnswerCard(card.id);
      });

      state.cardListFlat = state.cardListFlat.filter(item => !item.isChecked);
      state.checkedAnswerCards = state.cardListFlat.length;
      if (!state.cardListFlat.length) {
        state.filesWithError = [];
      }
      closeConfirmationModal();
    }

    const toggleCardsCheck = (props: any): void => {
      if (!allCardsInGroupChecked(props.cardGroupHash) && !props.event.target.checked) {
        return;
      }

      state.cardListGrouped.find(item => item.hash === props.cardGroupHash)?.answerCardList.forEach((item: OmrAnswerCard) => {
        item.isChecked = props.event.target.checked;
      });
    }

    const changeStudentPoints = async (event: any): Promise<void> => {
      const card = state.cardListFlat.find(item => item.id === event.itemId);
      if (card && card.score.scoreUserAnswer) {
        card.score.scoreUserAnswer[event.answerIndex] = event.points;
        card.score.scoreUser = updateTotalScoreUser(card.score.scoreUserAnswer);
        if (card.score.answer[event.answerIndex]) card.score.answer[event.answerIndex]['isChanged'] = true;
        await state.indexDB.updateAnswerCard(card);
        dataLayerPush(DataLayerEvent.STUDENT_SCORE_HAS_CHANGED, { isSuccess: true});
      }
    }

    const changeStudentNumber = async (event: any): Promise<void> => {
      const card = state.cardListFlat.find(item => item.id === event.itemId);
      if (card) {
        const parseNumberInt = parseInt(event.value);
        const positiveStudentNumber = Math.abs(parseNumberInt);
        card.studentNumber = isNaN(parseNumberInt) ? 0 : (positiveStudentNumber > 99 ? 99 : Math.abs(positiveStudentNumber));

        await state.indexDB.updateAnswerCard(card);
      }
      dataLayerPush(DataLayerEvent.STUDENT_NUMBER_HAS_CHANGED, { isSuccess: true});
    }

    return {
      ...toRefs(state),
      trashOutline,
      content,
      clearLastCheckedCard,
      closeConfirmationModal,
      closeErrorsCard,
      // TODO: exposed due to unit tests. Should be deleted
      groupByTestHash,
      onAnswerCardModalOpen,
      openConfirmationModal,
      removeAnswerCards,
      onAnswerKeyModalOpen,
      setAnswerCardModalOpen,
      setAnswerKeyModalOpen,
      addMockAnswerCard,
      uploadPicture,
      uploadMultiplePictures,
      getTestTitle,
      deleteCheckedCards,
      isDeleteButtonVisible,
      toggleCardsCheck,
      allCardsInGroupChecked,
      getCurrentAnswerKey,
      getCurrentAnswerKeys,
      handleAnswerCard,
      changeStudentNumber,
      changeStudentPoints,
      goToAnswerKey,
      isRemoveBtnVisible
    }
  },
  watch: {
    $route(to, from) {
      this.isAnswerCardModalOpen = from.hash === ROUTE_HASH.ANSWER_CARD_MODAL && to.hash === '' ? false : this.isAnswerCardModalOpen;
      this.isAnswerKeyModalOpen = from.hash === ROUTE_HASH.ANSWER_KEY_MODAL && to.hash === '' ? false : this.isAnswerKeyModalOpen;
    }
  }
});

