import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_loading = _resolveComponent("ion-loading")!

  return (_openBlock(), _createBlock(_component_ion_loading, {
    cssClass: "gt-scores-loader",
    "is-open": _ctx.isLoading,
    message: "Ładowanie"
  }, null, 8, ["is-open"]))
}