

import { defineComponent } from 'vue';


export default defineComponent({
  name: 'ModalNavItem',
  props: {
    index: Number,
    isActive: {
      type: Boolean,
      default: false
    }
  },
})
