import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "gt-scores-answer-card-loader__empty-list-info" }
const _hoisted_2 = { class: "gt-scores-answer-card-loader__progress-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "gt-scores-answer-card-loader__progress-card" }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('ANSWER_CARD.check_answers')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(`${_ctx.displayProgress}%`), 1),
          _createVNode(_component_ion_progress_bar, {
            value: _ctx.progress,
            color: "success"
          }, null, 8, ["value"])
        ])
      ])
    ]),
    _: 1
  }))
}