
import { defineComponent, PropType, reactive, ref } from 'vue';
import { OmrAnswerCard } from '@/services/api/models/OmrAnswerCard';
import ModalNav from '@/components/ModalNav.vue';
import ModalNavItem from '@/components/ModalNavItem.vue';
import AnswerCardPunctation from '@/components/AnswerCardPunctation.vue';
import SwiperCore, { Zoom } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/components/zoom/zoom.scss';
import 'swiper/swiper.scss';
import { GtTest } from '@/models/gt-test';
import { isMobile } from '@/utils/DeviceUtils';
import { dataLayerPush } from '@/services/common/DataLayerService';
import { DataLayerEvent } from '@/services/common/DataLayerModel';
import AnswerCardLegend from '@/components/shared/AnswerCardLegend.vue';
import { GoToAnswerKeyEventData } from '@/views/AnswerCard/models';

SwiperCore.use([Zoom]);


export default defineComponent({
  name: 'AnswerCardModal',
  components: { Swiper, SwiperSlide, ModalNavItem, ModalNav, AnswerCardLegend, AnswerCardPunctation },
  props: {
    studentCard: Object as PropType<OmrAnswerCard>,
    answerKey: Object as PropType<GtTest>,
  },
  setup() {
    const swiperRef = ref<any>(null);
    const swiperVariables = reactive({
      swiperSlideClass: 'zoom-in'
    })

    const getZoomType  = (currentScale: number): string => {
      if (currentScale > 1) {
        dataLayerPush(DataLayerEvent.ZOOM);
        return 'zoom-out';
      } else {
        return 'zoom-in'
      }
    }

    const onSwiperInit = async (swiper: any) => {
      swiperRef.value = swiper;
      swiperRef.value.on('click', () => {
        if (!isMobile()) {
          swiperRef.value.zoom.toggle();
          swiperVariables.swiperSlideClass = getZoomType(swiperRef.value.zoom.currentScale);
        }
      });
      swiperRef.value.on('doubleClick', () => {
        swiperVariables.swiperSlideClass = getZoomType(swiperRef.value.zoom.currentScale);
      });
      swiperRef.value.on('touchStart', () => {
        if (swiperRef.value.zoom.currentScale > 1) {
          swiperVariables.swiperSlideClass = 'move';
        }
      });
      swiperRef.value.on('touchEnd', () => {
        if (swiperRef.value.zoom.currentScale > 1) {
          swiperVariables.swiperSlideClass = 'zoom-out';
        }
      });

      setTimeout(() => {
        swiperRef.value.update()
      }, 100);
    };

    return {
      swiperVariables,
      onSwiperInit,
      swiperRef,
      modules: [Zoom],
    };
  },
  data() {
    return {
      isPunctationTabActive: false,
    }
  },
  computed: {
    currentCardSrc(): string {
      return 'data:image/jpeg;' + this.studentCard?.file.process
    }
  },
  methods: {
    togglePunctationTab(isActive: boolean): void {
      this.isPunctationTabActive = isActive;
      this.swiperVariables.swiperSlideClass = 'zoom-in';
      dataLayerPush(DataLayerEvent.ANSWER_CARD_MODAL_TAB_CHANGE, { tabName: isActive ? 'Punktacja' : 'Karta odpowiedzi' })
    },
    goToAnswerKey (event: GoToAnswerKeyEventData) {
      this.$emit('goToAnswerKey', event );
    },
    changeStudentNumber(event: Event) {
      this.$emit('changeStudentNumber', event );
    },
    changeStudentPoints(event: Event) {
      this.$emit('changeStudentPoints', event);
    }
  }
});

