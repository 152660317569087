
import { defineComponent } from 'vue';
import Card from '@/components/shared/Card.vue';


export default defineComponent ({
  components: { Card },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  }
})

