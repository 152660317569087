import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "gt-scores-number-tiles" }
const _hoisted_2 = { class: "gt-scores-number-tiles__header" }
const _hoisted_3 = { class: "gt-scores-number-tiles__change-number-tiles" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t('OTHERS.change_score_number')) + " ", 1),
      _createElementVNode("strong", null, _toDisplayString(_ctx.answerIndex + 1), 1),
      _createTextVNode(". ")
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numbers, (item, index) => {
        return (_openBlock(), _createElementBlock("span", {
          key: index,
          onClick: ($event: any) => (_ctx.chooseNumber(item)),
          class: _normalizeClass(["gt-scores-number-tiles__change-number-tile", {'gt-scores-number-tiles__change-number-tile--disabled': item === _ctx.currentPoints }])
        }, _toDisplayString(item), 11, _hoisted_4))
      }), 128))
    ])
  ]))
}