export const blobFromBase64 = (base64: string, format = 'jpg') => {
  const rawData = atob(base64);
  const bytes = new Array(rawData.length);

  for (let x = 0; x < rawData.length; x++) {
    bytes[x] = rawData.charCodeAt(x);
  }

  const arr = new Uint8Array(bytes);
  const type = `image/${format}`;
  return new Blob([arr], {type: type});
}

export const formatBytesToKilobytes = (bytes: number): number => {
  if (bytes === 0) return 0;
  const k = 1024;

  return Math.round(bytes / k);
}
