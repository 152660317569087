import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onClick', _ctx.index))),
    class: _normalizeClass(["gt-scores-modal-nav-item", {'gt-scores-modal-nav-item--active': _ctx.isActive}])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["gt-scores-modal-nav-item__text", {'gt-scores-modal-nav-item__text--active': _ctx.isActive}])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ], 2))
}