import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "gt-scores-answer-card__header" }
const _hoisted_2 = {
  key: 0,
  class: "gt-scores-answer-card__checked-cards"
}
const _hoisted_3 = { class: "gt-scores-answer-card" }
const _hoisted_4 = { class: "gt-scores-answer-card__container" }
const _hoisted_5 = {
  key: 2,
  class: "gt-scores-answer-card__wrapper"
}
const _hoisted_6 = {
  key: 0,
  class: "gt-scores-answer-card__card-limit-warning"
}
const _hoisted_7 = { class: "gt-scores-answer-card__test-title" }
const _hoisted_8 = { class: "gt-scores-answer-card__answer-card-buttons" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 1,
  class: "gt-scores-answer-card__remove-btn-wrapper"
}
const _hoisted_12 = { class: "gt-scores-answer-card__remove-btn" }
const _hoisted_13 = { class: "gt-scores-answer-card__confirmation-modal" }
const _hoisted_14 = { class: "gt-scores-answer-card__confirmation-modal-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_AnswerCardAddInfo = _resolveComponent("AnswerCardAddInfo")!
  const _component_AnswerCardErrors = _resolveComponent("AnswerCardErrors")!
  const _component_AnswerCardLoader = _resolveComponent("AnswerCardLoader")!
  const _component_DownloadResults = _resolveComponent("DownloadResults")!
  const _component_AnswerCardListHeader = _resolveComponent("AnswerCardListHeader")!
  const _component_AnswerCardList = _resolveComponent("AnswerCardList")!
  const _component_Card = _resolveComponent("Card")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Camera = _resolveComponent("Camera")!
  const _component_AnswerCardModal = _resolveComponent("AnswerCardModal")!
  const _component_modal = _resolveComponent("modal")!
  const _component_AnswerKeyModal = _resolveComponent("AnswerKeyModal")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_Loader = _resolveComponent("Loader")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        ref: "content",
        scrollevents: true,
        fullscreen: true,
        class: "gt-scores-answer-card"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Header, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("span", {
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addMockAnswerCard && _ctx.addMockAnswerCard(...args)))
                }, _toDisplayString(_ctx.$t('ANSWER_CARD.answer_card')), 1),
                (_ctx.cardListFlat.length)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
                      _createTextVNode(_toDisplayString(_ctx.$t('ANSWER_CARD.checked')) + ": ", 1),
                      _createElementVNode("span", null, _toDisplayString(_ctx.checkedAnswerCards), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (!_ctx.cardListGrouped.length && !_ctx.filesWithError.length)
                ? (_openBlock(), _createBlock(_component_AnswerCardAddInfo, {
                    key: 0,
                    class: "gt-scores-answer-card__info-wrapper",
                    "is-mobile": _ctx.isMobile
                  }, null, 8, ["is-mobile"]))
                : _createCommentVNode("", true),
              (_ctx.filesWithError.length)
                ? (_openBlock(), _createBlock(_component_AnswerCardErrors, {
                    key: 1,
                    "files-with-error": _ctx.filesWithError,
                    onCloseErrorsCard: _ctx.closeErrorsCard
                  }, null, 8, ["files-with-error", "onCloseErrorsCard"]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.loaderItemList, (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (!item.loaded)
                    ? (_openBlock(), _createBlock(_component_AnswerCardLoader, {
                        key: 0,
                        class: "gt-scores-answer-card__loader-wrapper"
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128)),
              (_ctx.cardListGrouped.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardListGrouped, (cardGroup) => {
                      return (_openBlock(), _createBlock(_component_Card, {
                        class: "gt-scores-answer-card__wrapper-item",
                        key: cardGroup.id
                      }, {
                        body: _withCtx(() => [
                          (cardGroup.answerCardList.length > _ctx.config.cardLimit)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('ANSWER_CARD.answer_card_limit_warning')), 1))
                            : _createCommentVNode("", true),
                          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.getTestTitle(cardGroup.hash)), 1),
                          _createElementVNode("div", _hoisted_8, [
                            (!_ctx.isDeleteButtonVisible(cardGroup.id))
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "gt-scores-answer-card__answer-key",
                                  onClick: ($event: any) => (_ctx.onAnswerKeyModalOpen(cardGroup.hash, cardGroup.answerCardList[0].score.scoreQuestion))
                                }, _toDisplayString(_ctx.$t('ANSWER_CARD.answer_key')), 9, _hoisted_9))
                              : _createCommentVNode("", true),
                            (!_ctx.isDeleteButtonVisible(cardGroup.id) && cardGroup.isAnswerKeyFetched)
                              ? (_openBlock(), _createBlock(_component_DownloadResults, {
                                  key: 1,
                                  "user-id": _ctx.userId,
                                  disabled: _ctx.loaderItemList.length > 0 || cardGroup.answerCardList.length > _ctx.config.cardLimit,
                                  "card-group": cardGroup,
                                  "answer-keys": _ctx.getCurrentAnswerKeys(cardGroup.id)
                                }, null, 8, ["user-id", "disabled", "card-group", "answer-keys"]))
                              : _createCommentVNode("", true),
                            (_ctx.isDeleteButtonVisible(cardGroup.id))
                              ? (_openBlock(), _createElementBlock("p", {
                                  key: 2,
                                  innerHTML: _ctx.$t('ANSWER_CARD.remove_answer_cards_info'),
                                  class: "gt-scores-answer-card__delete-info"
                                }, null, 8, _hoisted_10))
                              : _createCommentVNode("", true)
                          ]),
                          _createVNode(_component_AnswerCardListHeader, {
                            "all-cards-checked": _ctx.allCardsInGroupChecked(cardGroup.hash),
                            "is-magnifier-row-visible": true,
                            "card-group-hash": cardGroup.hash,
                            onOnCheckboxChange: _ctx.toggleCardsCheck
                          }, null, 8, ["all-cards-checked", "card-group-hash", "onOnCheckboxChange"]),
                          _createVNode(_component_AnswerCardList, {
                            "is-pointer-visible": "",
                            "card-list": cardGroup.answerCardList,
                            "last-checked-card": _ctx.lastCheckedCard,
                            "is-multiple-files": _ctx.isMultipleFiles,
                            "answer-keys": _ctx.answerKeys,
                            "parent-element": _ctx.content,
                            onOnAnswerCardModalOpen: _ctx.onAnswerCardModalOpen,
                            onChangeStudentNumber: _ctx.changeStudentNumber,
                            onClearLastCheckedCard: _ctx.clearLastCheckedCard
                          }, null, 8, ["card-list", "last-checked-card", "is-multiple-files", "answer-keys", "parent-element", "onOnAnswerCardModalOpen", "onChangeStudentNumber", "onClearLastCheckedCard"]),
                          (_ctx.isRemoveBtnVisible)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                _createElementVNode("button", _hoisted_12, _toDisplayString(_ctx.$t('ANSWER_CARD.remove_answer_cards')), 1)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["gt-scores-answer-card__camera-wrapper", {'gt-scores-answer-card__camera-wrapper--desktop': !_ctx.isMobile}])
            }, [
              (_ctx.isDeleteButtonVisible())
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "gt-scores-answer-card__delete-button",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openConfirmationModal && _ctx.openConfirmationModal(...args)))
                  }, _toDisplayString(_ctx.$t('ANSWER_CARD.remove_answer_cards')), 1))
                : _createCommentVNode("", true),
              (!_ctx.isDeleteButtonVisible())
                ? (_openBlock(), _createBlock(_component_FileUpload, {
                    key: 1,
                    class: _normalizeClass(["gt-scores-answer-card__file-item", {'gt-scores-answer-card__file-item--desktop': !_ctx.isMobile}]),
                    "button-text": _ctx.$t('ANSWER_CARD.upload_file_desktop'),
                    onOnTakePicture: _cache[2] || (_cache[2] = ($event: any) => (_ctx.uploadPicture($event, true))),
                    onOnTakeMultiplePicture: _cache[3] || (_cache[3] = ($event: any) => (_ctx.uploadMultiplePictures($event)))
                  }, null, 8, ["class", "button-text"]))
                : _createCommentVNode("", true),
              (_ctx.isMobile && !_ctx.isDeleteButtonVisible())
                ? (_openBlock(), _createBlock(_component_Camera, {
                    key: 2,
                    class: "gt-scores-answer-card__file-item",
                    "button-text": _ctx.$t('CAMERA.take_picture'),
                    "is-bubble-speech-open": _ctx.isBubbleSpeechOpen,
                    onOnBubbleSpeechClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isBubbleSpeechOpen = false)),
                    onOnTakePicture: _cache[5] || (_cache[5] = ($event: any) => (_ctx.uploadPicture($event, false)))
                  }, null, 8, ["button-text", "is-bubble-speech-open"]))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          _createVNode(_component_modal, {
            "header-text": _ctx.$t('ANSWER_CARD.solution_details'),
            "is-open": _ctx.isAnswerCardModalOpen,
            onOnModalOpen: _ctx.setAnswerCardModalOpen
          }, {
            default: _withCtx(() => [
              (_ctx.getCurrentAnswerKey(_ctx.currentStudentCard.test.hash))
                ? (_openBlock(), _createBlock(_component_AnswerCardModal, {
                    key: 0,
                    "student-card": _ctx.currentStudentCard,
                    "answer-key": _ctx.getCurrentAnswerKey(_ctx.currentStudentCard.test.hash),
                    onGoToAnswerKey: _cache[6] || (_cache[6] = ($event: any) => (_ctx.goToAnswerKey($event))),
                    onChangeStudentNumber: _ctx.changeStudentNumber,
                    onChangeStudentPoints: _ctx.changeStudentPoints
                  }, null, 8, ["student-card", "answer-key", "onChangeStudentNumber", "onChangeStudentPoints"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header-text", "is-open", "onOnModalOpen"]),
          _createVNode(_component_modal, {
            "header-text": _ctx.$t('ANSWER_CARD.remove_answer_cards'),
            "is-open": _ctx.isConfirmationModalOpen,
            onOnModalOpen: _ctx.openConfirmationModal
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("strong", null, _toDisplayString(_ctx.$t('ANSWER_CARD.remove_answer_cards_warning')), 1),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("button", {
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.closeConfirmationModal && _ctx.closeConfirmationModal(...args))),
                    class: "gt-scores-answer-card__confirmation-modal-button gt-scores-answer-card__confirmation-modal-button--cancel"
                  }, _toDisplayString(_ctx.$t('ANSWER_CARD.remove_answer_cards_no')), 1),
                  _createElementVNode("button", {
                    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.deleteCheckedCards && _ctx.deleteCheckedCards(...args))),
                    class: "gt-scores-answer-card__confirmation-modal-button gt-scores-answer-card__confirmation-modal-button--confirm"
                  }, _toDisplayString(_ctx.$t('ANSWER_CARD.remove_answer_cards_yes')), 1)
                ])
              ])
            ]),
            _: 1
          }, 8, ["header-text", "is-open", "onOnModalOpen"]),
          _createVNode(_component_modal, {
            "header-text": _ctx.$t('ANSWER_CARD.answer_key'),
            "is-open": _ctx.isAnswerKeyModalOpen,
            width: 'lg',
            onOnModalOpen: _ctx.setAnswerKeyModalOpen
          }, {
            default: _withCtx(() => [
              (_ctx.currentAnswerKey)
                ? (_openBlock(), _createBlock(_component_AnswerKeyModal, {
                    key: 0,
                    "score-question": _ctx.currentScoreQuestion,
                    "exercise-number-to-scroll": _ctx.exerciseIndexToScroll,
                    "onUpdate:exercise-number-to-scroll": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.exerciseIndexToScroll) = $event)),
                    "variant-index-to-scroll": _ctx.exerciseVariantToScroll,
                    "onUpdate:variant-index-to-scroll": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.exerciseVariantToScroll) = $event)),
                    "test-title": _ctx.currentAnswerKey.title,
                    "gt-test": _ctx.currentAnswerKey
                  }, null, 8, ["score-question", "exercise-number-to-scroll", "variant-index-to-scroll", "test-title", "gt-test"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header-text", "is-open", "onOnModalOpen"])
        ]),
        _: 1
      }, 512),
      (_ctx.isAnswerKeyLoading)
        ? (_openBlock(), _createBlock(_component_Loader, {
            key: 0,
            "is-loading": _ctx.isAnswerKeyLoading
          }, null, 8, ["is-loading"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}