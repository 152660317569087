import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "gt-scores-answer-card-modal" }
const _hoisted_2 = { class: "gt-scores-answer-card-modal__test-title" }
const _hoisted_3 = { class: "gt-scores-answer-card-modal__body-wrapper" }
const _hoisted_4 = {
  key: 1,
  class: "gt-scores-answer-card-modal__answer-card-wrapper"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalNavItem = _resolveComponent("ModalNavItem")!
  const _component_ModalNav = _resolveComponent("ModalNav")!
  const _component_AnswerCardPunctation = _resolveComponent("AnswerCardPunctation")!
  const _component_AnswerCardLegend = _resolveComponent("AnswerCardLegend")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.studentCard.test.title), 1),
    _createVNode(_component_ModalNav, null, {
      default: _withCtx(() => [
        _createVNode(_component_ModalNavItem, {
          "is-active": !_ctx.isPunctationTabActive,
          onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.togglePunctationTab(false)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('ANSWER_CARD.answer_card')), 1)
          ]),
          _: 1
        }, 8, ["is-active"]),
        _createVNode(_component_ModalNavItem, {
          "is-active": _ctx.isPunctationTabActive,
          onOnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.togglePunctationTab(true)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('OTHERS.punctation')), 1)
          ]),
          _: 1
        }, 8, ["is-active"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.isPunctationTabActive)
        ? (_openBlock(), _createBlock(_component_AnswerCardPunctation, {
            key: 0,
            "student-card": _ctx.studentCard,
            "answer-key": _ctx.answerKey,
            onChangeStudentNumber: _ctx.changeStudentNumber,
            onChangeStudentPoints: _ctx.changeStudentPoints,
            onGoToAnswerKey: _ctx.goToAnswerKey
          }, null, 8, ["student-card", "answer-key", "onChangeStudentNumber", "onChangeStudentPoints", "onGoToAnswerKey"]))
        : _createCommentVNode("", true),
      (!_ctx.isPunctationTabActive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('OTHERS.answers_legend')), 1),
            _createVNode(_component_AnswerCardLegend),
            _createVNode(_component_swiper, {
              allowTouchMove: false,
              "slides-per-view": 1,
              modules: _ctx.modules,
              zoom: "",
              onSwiper: _ctx.onSwiperInit
            }, {
              default: _withCtx(() => [
                _createVNode(_component_swiper_slide, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: _normalizeClass(["swiper-zoom-container", _ctx.swiperVariables.swiperSlideClass])
                    }, [
                      _createElementVNode("img", { src: _ctx.currentCardSrc }, null, 8, _hoisted_5)
                    ], 2)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modules", "onSwiper"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}